import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Api from '../apis';
import * as liff from '../apis/liff';
import Layout from '../components/Layout';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { getUrlParams } from '../helpers/location';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import { navigate } from 'gatsby';
import Header from '../components/Header';
import useActiveSession from '../hooks/useActiveSession';
import { useDispatch } from 'react-redux';
import { setUserQuestionnaireId } from '../actions/questionnaire';
import withAuth from '../hooks/withAuth';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    height: '70px',
    background: 'white',
    marginBottom: '10px',
    boxShadow: '0px 0px 1px #3e3e3e',
  },
  header: {
    paddingTop: 15,
    textAlign: 'center',
  },
  categoryImage: {
    width: '100%',
  },
  cardContainer: {
    height: '160px',
  },
  cardImage: {
    width: '35%',
    padding: 5,
  },
  image: {
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    padding: '10px',
  },
  cardDetail: {
    width: '65%',
    padding: 5,
    height: '100%',
  },
  textDescription: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    lineHeight: 1.8,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  backButton: {
    marginLeft: 10,
  },
  bottomDetail: {
    position: 'absolute',
    bottom: 10,
    fontWeight: 'bold',
    fontSize: '14px',
    color: 'green',
  },
  dot: {
    height: 20,
    width: 20,
    borderRadius: '50%',
    position: 'absolute',
    left: 10,
    top: 10,
  },
  bgGreen: {
    backgroundColor: '#09b709',
  },
  bgRed: {
    backgroundColor: '#e60f0f',
  },
}));

const Category = ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = getUrlParams(location.search);
  const [consultants, setConsultants] = useState([]);
  const [category, setCategory] = useState(null);
  const { category_id, user_question_id = '' } = query;

  useActiveSession(() => {
    Api.postCountData('consult', user_question_id);
    if (user_question_id) {
      dispatch(setUserQuestionnaireId(user_question_id));
    }

    if (category_id) {
      Api.getCategoryById(category_id).then(data => {
        setCategory(data);
      });
      Api.getConsultants(category_id).then(data => {
        setConsultants(data.results);
      });
    }
  });

  if (!category_id || !category) {
    return false;
  }

  return (
    <Layout>
      <Header
        backAction={() => {
          navigate('/categories');
        }}
        text={category.name}
      />
      <h2 style={{ textAlign: 'center' }}>กรุณาเลือกที่ปรึกษา</h2>
      <Grid container spacing={2}>
        {consultants.map(consultant => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{ marginBottom: 1 }}
            key={consultant.id}
          >
            <Card>
              <CardActionArea
                onClick={() => {
                  navigate(`/appointment`, {
                    state: { consultant },
                  });
                }}
              >
                <Box display='flex' className={classes.cardContainer}>
                  <Box
                    display='flex'
                    justifyContent='center'
                    className={classes.cardImage}
                  >
                    <CardMedia
                      component='img'
                      image={consultant.image}
                      className={classes.image}
                    />
                    <span
                      className={`${classes.dot} ${
                        consultant.is_online ? classes.bgGreen : classes.bgRed
                      }`}
                    ></span>
                  </Box>
                  <Box className={classes.cardDetail}>
                    <h3>{consultant.display_name}</h3>
                    <p className={classes.textDescription}>
                      {consultant.description}
                    </p>
                    <Box className={classes.bottomDetail}>
                      <span>{consultant.price} บาท</span>
                    </Box>
                  </Box>
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default withAuth(Category);
