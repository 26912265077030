import * as Constant from './constants';
import * as Api from '../apis';

export const setQuestionnaireId = questionnaireId => ({
  type: Constant.SET_QUESTIONNAIRE_ID,
  questionnaireId,
});

export const setUserQuestionnaireId = userQuestionnaireId => ({
  type: Constant.SET_USER_QUESTIONNAIRE_ID,
  userQuestionnaireId,
});

export const getCategories = () => {
  return dispatch => {
    dispatch({ type: Constant.GET_CATEGORIES_REQUEST });
    return Api.getCategories()
      .then(result => {
        dispatch({
          type: Constant.GET_CATEGORIES_SUCCESS,
          payload: result.results,
        });
      })
      .catch(_ => {
        dispatch({ type: Constant.GET_CATEGORIES_FAILED, payload: [] });
      });
  };
};
